export function userByInvite({ users }) {
  return users.userByInvite;
}

export function userInfo({ users }) {
  return users.userInfo;
}

export function showReportNotesWarning({ users }) {
  return !users.userInfo.skippedReportNotesNotification;
}
