import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { replaceNullWithEmptyStringsInObject } from "utils/FormatUtils";
import { CONTACT_MAX_LENGTHS } from "utils/constants";
import { FormikField, Input, Select, Checkbox, Tooltip } from "components";
import DesignationField from "common/components/DesignationField";
import UploadImage from "common/components/UploadImage";
import AuthService, { ROLES_IDS } from "services/AuthService";
import * as TeamMembersActions from "myCompany/store/teamMembers.reducer";
import TeamMembersService from "myCompany/api/TeamMembersService";
import { getCountries } from "projects/store/contacts.selectors";

import { getDesignations } from "myCompany/store/rates.selectors";
import {
  MEMBER_ICC_SCHEMA,
  MEMBER_RCC_SCHEMA,
  MEMBER_RE_SCHEMA,
  newContactInitialValues,
  REPosition,
  CoordinatorPosition,
  positionsCompanyTeamMembers,
} from "../constants";
import {
  getFormProps,
  getAllowedPositions,
  getSendInviteTooltip,
} from "./helpers";
import { Wrapper, CheckboxWrapper, Row, StyledFormDialog } from "./styles";
import { DESIGNATIONS } from "../../Rates/constants";
import MyCompanyService from "../../../api/MyCompanyService";
import { COMPANY_TYPES } from "types/company";
import { CONTACT_POSITION } from "types/user";

const TeamMembersForm = ({
  isOpen,
  mode: initialMode,
  onCancel,
  onSubmit,
  onClose,
  member,
  locationId,
}) => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState(initialMode || "add");
  const [isLoading, setIsLoading] = useState(true);
  const countriesData = useSelector(getCountries);
  const designations = useSelector(getDesignations);
  const [additionalDesignations, setAdditionalDesignations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isPrimaryCoordinator, setIsPrimaryCoordinator] = useState(false);

  const [memberData, setMemberData] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [positionsCount, setPositionsCount] = useState({ data: [] });

  const [values, setValues] = useState(
    member || { ...newContactInitialValues, location: locationId }
  );
  const [fieldsToChange, setFieldsToChange] = useState(null);
  const { address } = values;
  const { country } = address || {};

  const isAdd = mode === "add";
  const isEdit = mode === "edit";
  const isView = mode === "view";

  let dialogTitle;
  if (isAdd) {
    dialogTitle = "New Team Member";
  }
  if (isEdit) {
    dialogTitle = "Edit Team Member";
  }
  if (isView) {
    dialogTitle = "View Team Member";
  }

  const { companyType, subscription } = AuthService.getUser();
  const { email } = AuthService.getUserData();

  const isPrincipalCompany =
    companyType === COMPANY_TYPES.INSURANCE ||
    companyType === COMPANY_TYPES.BROKER;
  const isRC = companyType === COMPANY_TYPES.RISK;

  const isRE = values.position === REPosition;
  const isCoordinator = values.position === CoordinatorPosition;
  const isICC = !isRE && isPrincipalCompany;
  const isRCC = !isRE && isRC;

  const readOnlyMode = isView;

  const stateComponent = ["United States", "United Kingdom"].includes(country)
    ? Select
    : Input;
  const stateLabel = country === "United States" ? "State" : "Region";
  const zipCodeLabel =
    country === "United Kingdom" ? "Postal Code" : "Zip Code";

  const countryOptions = countriesData.map((countryItem) => ({
    label: countryItem.name,
    value: countryItem.name,
  }));

  const countryStates =
    countriesData.find((countryItem) => countryItem.name === country)?.states ||
    [];
  const stateOptions = countryStates.map((state) => ({
    label: state,
    value: state,
  }));

  const formInitialValues = {
    ...newContactInitialValues,
    ...memberData,
    ...values,
    sendInvite:
      values.position === ROLES_IDS.ICCoordinator ||
      values.position === ROLES_IDS.ICUnderwriter,
    location: memberData?.location ?? values.location,
  };

  let validationSchema;
  if (isRE) {
    validationSchema = MEMBER_RE_SCHEMA;
  }
  if (isICC) {
    validationSchema = MEMBER_ICC_SCHEMA;
  }
  if (isRCC) {
    validationSchema = MEMBER_RCC_SCHEMA;
  }

  const changeModeToEdit = () => {
    setMode("edit");
  };

  const handleSubmit = async (formValues, formikBag) => {
    const response = await onSubmit(formValues, formikBag);
    if (response.fieldErrors && response.fieldErrors.email) {
      setEmailError(response.fieldErrors);
    }
    return response;
  };

  const { submitButtonTitle, noSubmit, submitMethod } = getFormProps({
    isView,
    isEdit,
    member,
    onClose,
    changeModeToEdit,
    handleSubmit,
  });

  const fetchMemberData = async (id) => {
    const fetchedMemberData = await dispatch(
      TeamMembersActions.getTeamMember(id)
    );
    setMemberData({
      ...replaceNullWithEmptyStringsInObject(fetchedMemberData),
      id,
    });
    setIsLoading(false);
  };

  const fetchPositionsCount = async () => {
    const { data } = await TeamMembersService.getTeamMemberCount();
    setPositionsCount(data);
  };

  useEffect(() => {
    if (isAdd) {
      setIsLoading(false);
    }
    fetchPositionsCount();
  }, []);

  const fetchCompanyData = async () => {
    const {
      data: { data },
    } = await MyCompanyService.getInsuranceCompanyInfo();
    setIsPrimaryCoordinator(data.primaryContact.email === email);
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const fetchLocations = async () => {
    const { data } = await MyCompanyService.getInsuranceCompanyLocations();
    setLocations(data.data.map((t) => ({ value: t.id, label: t.name })));
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    if (member && member.id) {
      fetchMemberData(member.id);
    }
  }, [member]);

  useEffect(() => {
    const newDesignations = [];
    designations.forEach((designation) => {
      const desig = DESIGNATIONS.find((t) => t.value === designation);
      if (desig == null) {
        newDesignations.push({ value: designation, label: designation });
      }
    });
    setAdditionalDesignations(newDesignations);
  }, [designations]);

  return (
    <StyledFormDialog
      isLoading={isLoading}
      title={dialogTitle}
      submitButtonTitle={submitButtonTitle}
      open={isOpen}
      onCancel={onCancel}
      onSubmit={submitMethod}
      noCancel={isView}
      onClose={!noSubmit && onClose}
      onFormChange={setValues}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      fieldsToChange={fieldsToChange}
    >
      <Wrapper>
        <Row>
          <FormikField
            component={Select}
            required
            readOnly={readOnlyMode}
            name="position"
            label="System Role"
            maxLength={CONTACT_MAX_LENGTHS.name}
            options={getAllowedPositions({
              positions: positionsCompanyTeamMembers[companyType],
              positionsCount: positionsCount.data,
              currentPackage: subscription,
              companyType,
              isPrimaryIC: isPrimaryCoordinator,
              addSeatsLeft: !readOnlyMode,
            })}
          />
          <DesignationField
            isView={isView}
            name="designation"
            readOnly={readOnlyMode}
            isRE={isRE}
            required={isRE || isCoordinator}
            isCoordinator={isCoordinator}
            additionalDesignations={additionalDesignations}
          />
        </Row>

        {isCoordinator && (
          <Row>
            <FormikField
              component={Select}
              required
              readOnly={readOnlyMode}
              name={readOnlyMode ? "locationId" : "location"}
              label="Office Location"
              options={locations}
            />
          </Row>
        )}
        <Row>
          <FormikField
            component={Input}
            required
            readOnly={readOnlyMode}
            name="name.firstName"
            label="First Name"
            maxLength={CONTACT_MAX_LENGTHS.name}
          />

          <FormikField
            component={Input}
            required
            readOnly={readOnlyMode}
            name="name.lastName"
            label="Last Name"
            maxLength={CONTACT_MAX_LENGTHS.name}
          />
        </Row>

        <Row>
          <FormikField
            component={Input}
            required
            readOnly={readOnlyMode}
            name="email"
            label="Email"
            maxLength={CONTACT_MAX_LENGTHS.email}
            errorExpand={emailError}
          />

          <FormikField
            component={Select}
            readOnly={readOnlyMode}
            name="address.country"
            label="Country"
            options={countryOptions}
          />
        </Row>

        <FormikField
          component={Input}
          readOnly={readOnlyMode}
          name="address.addressLine"
          label="Address Line"
          maxLength={CONTACT_MAX_LENGTHS.address}
        />

        <Row>
          <FormikField
            component={stateComponent}
            readOnly={readOnlyMode}
            disabled={!country}
            name="address.state"
            label={stateLabel}
            options={stateOptions}
            maxLength={CONTACT_MAX_LENGTHS.state}
          />

          <FormikField
            component={Input}
            readOnly={readOnlyMode}
            name="address.city"
            label="City"
            maxLength={CONTACT_MAX_LENGTHS.city}
          />
        </Row>

        <Row>
          <FormikField
            component={Input}
            readOnly={readOnlyMode}
            name="address.zipCode"
            label={zipCodeLabel}
            maxLength={CONTACT_MAX_LENGTHS.zipCode}
          />

          <FormikField
            component={Input}
            readOnly={readOnlyMode}
            name="phone"
            label="Phone"
            type={readOnlyMode ? undefined : "phone"}
            phoneCountryMask={country}
          />
        </Row>

        {isRE && (
          <UploadImage
            name="cvFileId"
            setFieldsToChange={setFieldsToChange}
            fileInfo={formInitialValues.cvFileInfo}
            disabled={readOnlyMode}
            label="Upload CV"
          />
        )}
      </Wrapper>

      <CheckboxWrapper>
        <FormikField
          component={Checkbox}
          disabled
          name="sendInvite"
          label="Send Invite"
        />
        <Tooltip text={getSendInviteTooltip(values.position)} />
      </CheckboxWrapper>
    </StyledFormDialog>
  );
};

export default TeamMembersForm;
