import { ROLES_IDS } from 'services/AuthService';
import { CONTACT_STATUSES } from 'utils/constants';

import { PACKAGES_USER_COUNTS_BY_COMPANY_TYPE } from 'subscriptions/constants';

import { COMPANY_TYPES } from 'types/company';
import { TeamMembersPositionCountLimit } from 'types/teamMember';
import { LabelValue } from 'types';
import { CONTACT_POSITION } from 'types/user';
import { SUBSCRIPTION_PACKAGE } from 'types/subscription';
import { REPosition } from '../constants';

interface GetFormPropsParams {
  isView: boolean;
  member: any;
  onClose: () => void;
  changeModeToEdit: () => void;
  handleSubmit: () => void;
}

interface GetAllowedPositionsParams {
  positions: LabelValue[];
  positionsCount: TeamMembersPositionCountLimit[];
  currentPackage: SUBSCRIPTION_PACKAGE;
  companyType: COMPANY_TYPES;
  isPrimaryIC: boolean;
  addSeatsLeft: boolean;
}

export const getFormProps = ({ isView, member, onClose, changeModeToEdit, handleSubmit }: GetFormPropsParams) => {
  if (member && isView) {
    if (member.status !== CONTACT_STATUSES.noAccess.value) {
      return {
        submitButtonTitle: 'Close',
        noSubmit: true,
        submitMethod: onClose,
      };
    }

    return {
      submitButtonTitle: 'Update',
      noSubmit: true,
      submitMethod: changeModeToEdit,
    };
  }

  return {
    submitButtonTitle: 'Save',
    noSubmit: false,
    submitMethod: handleSubmit,
  };
};

function restrictByCoordinator(positions: LabelValue[], availableCount: number) {
  const tooltip = `You are only allowed to have a maximum of ${availableCount} Coordinators per company.`;
  const newPositions = positions
    .map((pos) => (pos.value === ROLES_IDS.ICCoordinator ? { ...pos, tooltip, disabled: true } : pos));

  return newPositions;
}

function restrictByRiskCoordinator(positions: LabelValue[], availableCount: number) {
  const tooltip = `You are only allowed to have a maximum of ${availableCount} Coordinators per company.`;
  const newPositions = positions
    .map((pos) => (pos.value === ROLES_IDS.RCCoordinator ? { ...pos, tooltip, disabled: true } : pos));

  return newPositions;
}

function addAvailablePositions(positions: LabelValue[], countArray: TeamMembersPositionCountLimit[]): LabelValue[] {
  return positions.map((p) => {
    if (p.value === REPosition) {
      const count = countArray.find(({ position }) => position === REPosition) || { allowedCount: 0, currentCount: 0 };

      return { ...p, label: `${p.label} - ${count.allowedCount - count.currentCount} seats left` };
    }
    if (p.value === ROLES_IDS.ICUnderwriter) {
      const count = countArray.find(({ position }) => position === ROLES_IDS.ICUnderwriter) || { allowedCount: 0, currentCount: 0 };

      return { ...p, label: `${p.label} - ${count.allowedCount - count.currentCount} seats left` };
    }
    if (p.value === ROLES_IDS.ICCoordinator) {
      const count = countArray.find(({ position }) => position === ROLES_IDS.ICCoordinator) || { allowedCount: 0, currentCount: 0 };

      return { ...p, label: `${p.label} - ${count.allowedCount - count.currentCount} seats left` };
    }

    return p;
  });
}

export function getAllowedPositions({ positions, positionsCount, currentPackage, companyType, isPrimaryIC = true, addSeatsLeft = false }: GetAllowedPositionsParams): LabelValue[] {
  let newPositions = positions;

  // console.log({ companyType, currentPackage, positionsCount, count: PACKAGES_USER_COUNTS_BY_COMPANY_TYPE[companyType][currentPackage], ede: PACKAGES_USER_COUNTS_BY_COMPANY_TYPE });

  const usersAllowed = PACKAGES_USER_COUNTS_BY_COMPANY_TYPE[companyType][currentPackage];

  const countArray = positionsCount || [];
  const coordinatorsCount = countArray.find(({ position }) => position === ROLES_IDS.ICCoordinator) || { allowedCount: 0, currentCount: 0 };
  const riskCoordinatorsCount = countArray.find(({ position }) => position === ROLES_IDS.RCCoordinator) || { allowedCount: 0, currentCount: 0 };

  const isCoordinatorsAllowed = coordinatorsCount.currentCount < coordinatorsCount.allowedCount;
  const isRiskCoordinatorsAllowed = riskCoordinatorsCount.currentCount < riskCoordinatorsCount.allowedCount;

  if (!isCoordinatorsAllowed) {
    newPositions = restrictByCoordinator(newPositions, usersAllowed.coordinators);
  }
  // Non Primary IC can no longer add coordinators
  if (!isPrimaryIC) {
    newPositions = newPositions.filter((t) => t.value !== ROLES_IDS.ICCoordinator);
  }
  if (!isRiskCoordinatorsAllowed) {
    newPositions = restrictByRiskCoordinator(newPositions, usersAllowed.riskCoordinators);
  }
  const response = addSeatsLeft && companyType !== COMPANY_TYPES.RISK ? addAvailablePositions(newPositions, countArray) : newPositions;

  console.log({ response, newPositions, countArray });

  return response;
}

export function getSendInviteTooltip(position: CONTACT_POSITION): string {
  const tooltips: { [key: string]: string } = {
    [CONTACT_POSITION.IC_COORDINATOR]: 'When the invite is sent, invited user will receive an invitation email to access the platform.',
    [CONTACT_POSITION.INTERNAL_RISK_ENGINEER]: 'Risk Engineers will receive the invitation email when they are assigned to the project.',
  };

  return tooltips[position] || tooltips.ICCoordinator;
}
