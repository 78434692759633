export const isNumberValid = (number: string, maxLength: number, maxValue: number, maxDecimals: number) => {
  const maxLengthValid = !maxLength || RegExp(`^-?[0-9,.]{0,${maxLength}}$`).test(number);
  const maxValueValid = !maxValue || ((/^-?$|[0-9,.]/).test(number) && Number(number) <= maxValue);
  let maxDecimalValid;

  if (maxDecimals === 0) {
    maxDecimalValid = /^$|^[0-9]+$/.test(number);
  } else {
    maxDecimalValid = !maxDecimals || RegExp(`^$|^[0-9]+(.[0-9]{1,${maxDecimals}})?$`).test(number);
  }

  return maxLengthValid && maxValueValid && maxDecimalValid;
};

export function isUnique(this: any, message: any) {
  return this.test('unique', message, (arrayValues: any[]) => {
    const {
      path,
    } = this;

    if (arrayValues.length !== new Set(arrayValues).size) {
      const notUniqueOnly = new Set(arrayValues.filter((v, i, a) => a.indexOf(v) !== i));

      arrayValues.map((el, i) => {
        if ([...notUniqueOnly].includes(el)) {
          throw this.createError({
            path: `${path}[${i}]`,
            message,
          });
        }

        return el;
      });
    }

    return true;
  });
}

export const noSpecialCharRegexp = /^[\w0-9()#№\s-/]+$/i;

export const checkIfHasSpecialChar = (str: string) => {
  const test = noSpecialCharRegexp.test(String(str)) || !str;

  return !test;
};

export const specialCharactersFilenameValidator = (file: { name: string }) => {
  if (checkIfHasSpecialChar(file.name.split('.')[0])) {
    return { code: 'name-has-special-characters' };
  }

  return null;
};
