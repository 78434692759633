import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';

import { StyledDateTimePicker, StyledIcon } from './styles';

const inputProps = {
  disableUnderline: true,
  endAdornment: <StyledIcon icon="calendar" />,
};

const DateTimePicker = ({
  required,
  disabled,
  label,
  error,
  value = null,
  minDate,
  maxDate,
  setValue,
  readOnly,
  type,
}) => {
  const handleDateChange = (newDate) => {
    if (setValue) {
      setValue(newDate, false);
    }
  };

  return (
    <FormControl fullWidth>
      <StyledDateTimePicker
        clearable
        variant="dialog"
        inputVariant="filled"
        fullWidth
        format="MM/dd/yyyy h:mm a"
        openTo="month"
        views={type === 'time' ? ['hours', 'minutes'] : ['year', 'month', 'date', 'hours', 'minutes']}
        label={label}
        hideTabs={type === 'time'}
        disabled={disabled && !readOnly}
        value={value === '' ? null : value}
        required={required}
        readOnly={readOnly}
        InputProps={inputProps}
        error={!!error}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleDateChange}
      />
      <FormHelperText variant="filled">
        {error || ' '}
      </FormHelperText>
    </FormControl>
  );
};

export default DateTimePicker;
