import { Action, configureStore, Middleware } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

import apiCallMiddleware from './apiCallMiddleware';
import payloadMiddleware from './payloadMiddleware';

import rootReducer, {
  createReduxHistory,
  routerMiddleWare,
} from './rootReducer';

export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware().prepend(
    routerMiddleWare,
    thunk,
    apiCallMiddleware as Middleware<(action: Action) => any, RootState>,
    payloadMiddleware,
  ),
});

export const history = createReduxHistory(store);

export default store;
