export function allNotificationsList({ notification }) {
  return notification.allNotifications.data;
}

export function allAlertsList({ notification }) {
  return notification.allAlerts.data;
}

export function newNotificationsList({ notification }) {
  const { data = [] } = notification.allNotifications;

  return data.filter((notif) => !notif.isViewed);
}

export function newAlertsList({ notification }) {
  const { data = [] } = notification.allAlerts;

  return data.filter((notif) => !notif.isViewed);
}

export function isNewAlerts({ notification }) {
  const newList = newAlertsList({ notification });

  return newList.length > 0;
}

export function isNewNotifications({ notification }) {
  const newList = newNotificationsList({ notification });

  return newList.length > 0;
}

export function notificationsView({ notification }) {
  return notification.notificationsView;
}

export function alertsView({ notification }) {
  return notification.alertsView;
}

export function getOpenedNotification({ notification }) {
  return notification.openedNotification;
}

export function getPendingNotification({ notification }) {
  return notification.pendingNotification || {};
}

export function getOpenedAlert({ notification }) {
  return notification.openedAlert;
}

export function globalTheme({ notification }) {
  return notification.theme;
}

export function slideOut({ notification }) {
  return notification.slideOut;
}
