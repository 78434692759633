import ReportNotesService from 'projects/api/ReportNotesService';
import { mapReportNotesToForm } from 'projects/pages/ReportNotes/formSchema';

const GET_REPORT_NOTES = 'GET_REPORT_NOTES';
const UPDATE_REPORT_NOTES = 'UPDATE_REPORT_NOTES';

export const getReportNotes = (params) => async (dispatch) => {
  const response = await ReportNotesService.getReportNotes(params);
  const reportNotes = mapReportNotesToForm(response.data.data);

  return dispatch({
    type: GET_REPORT_NOTES,
    response: reportNotes,
  });
};

export const updateReportNotes = (data) => async (dispatch) => {
  const newData = await ReportNotesService.updateReportNotes(data);

  return dispatch({
    type: UPDATE_REPORT_NOTES,
    response: newData.data.data,
  });
};

const initialState = {
  isLoading: true,
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_REPORT_NOTES: {
      return {
        ...response,
        isLoading: false,
      };
    }
    case UPDATE_REPORT_NOTES: {
      return {
        ...state,
        ...response,
      };
    }

    default:
      return state;
  }
};
