import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { set } from 'date-fns';

import { normalizeMonthDate, createUniversalDate, parseDateWithoutTime } from 'utils/DateUtils';

import { StyledDatePicker } from './styles';
import { StyledIcon } from '../Icon';

const inputProps = {
  disableUnderline: true,
  endAdornment: <StyledIcon icon="calendar" />,
};

const DatePicker = ({
  required,
  disabled,
  readOnly,
  label,
  error,
  value = null,
  disablePast,
  maxDateMessage,
  minDateMessage,
  disableFuture,
  minDate,
  maxDate,
  monthOnly,
  setValue,
}) => {
  const normalizedValue = parseDateWithoutTime(value);

  const handleDateChange = (newDate) => {
    let dateToSet = newDate;
    if (newDate) {
      dateToSet = createUniversalDate(newDate);
      if (monthOnly) {
        dateToSet = set(dateToSet, { date: 1 });
      }
    }
    if (setValue) {
      setValue(dateToSet, false);
    }
  };

  const maxDateNormalized = monthOnly ? normalizeMonthDate(maxDate, true) : maxDate;
  const minDateNormalized = monthOnly ? normalizeMonthDate(minDate) : minDate;

  return (
    <FormControl fullWidth>
      <StyledDatePicker
        clearable
        openTo="year"
        variant="dialog"
        inputVariant="filled"
        fullWidth
        autoOk
        format={monthOnly ? 'MMMM yyyy' : 'MM/dd/yyyy'}
        views={monthOnly ? ['year', 'month'] : ['year', 'month', 'date']}
        label={label}
        value={normalizedValue}
        disabled={disabled && !readOnly}
        required={required}
        readOnly={readOnly}
        InputProps={inputProps}
        error={!!error}
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDateNormalized}
        maxDate={maxDateNormalized}
        onChange={handleDateChange}
        maxDateMessage={maxDateMessage}
        minDateMessage={minDateMessage}
      />
      <FormHelperText variant="filled">
        {error || ' '}
      </FormHelperText>
    </FormControl>
  );
};

export default DatePicker;
