import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Button } from "@mui/material";

export const StyledButton = styledMui(Button)(
  ({ variant, theme, $small, inline }) => ({
    minWidth: 120,
    height: 48,
    borderRadius: 4,
    padding: "12px 36px",
    whiteSpace: "nowrap",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "none",
    ...($small && {
      height: 40,
      fontSize: 14,
      padding: "8px 24px",
    }),
    ...(variant === "text" && {
      padding: "4px 12px",
      height: "auto",
      minHeight: 30,
      fontFamily: "Graphik LC",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 17,
      "& .MuiButton-startIcon": {
        margin: "0 8px 0 0",
      },
    }),
    ...(inline && {
      padding: 0,
      minWidth: "auto",
      minHeight: "auto",
    }),
    "&.MuiButton-text": {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
    "&.MuiButton-textPrimary": {
      color: theme.palette.primary.main,
      "& svg > path": {
        fill: theme.palette.primary.main,
      },
      "&:hover": {
        color: theme.palette.primary.light,
        "& svg > path": {
          fill: theme.palette.primary.light,
        },
      },

      "&.Mui-disabled": {
        color: theme.palette.primary.light,
        "& svg > path": {
          fill: theme.palette.primary.light,
        },
      },
    },
    ".MuiButton-label": {
      pointerEvents: "none",
    },
  })
);

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  button.MuiButton-text {
    .MuiButton-startIcon {
      margin-left: 0px;
    }
  }

  > .progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`;

export const TooltipBlock = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;
