import styled from 'styled-components';
import DatePicker from '@mui/lab/DatePicker';

import Icon from 'components/Icon';

export const StyledIcon = styled(Icon)`
  svg > path {
    fill: var(--iconPrimaryColor);
  }
`;

export const StyledDateTimePicker = styled(DatePicker)`
  .MuiInputBase-root {
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--inputBackground);
    border: 1px solid var(--inputBorder);

    input {
      cursor: pointer;
    }

    ${({ error }) => error && 'border: 1px solid var(--error);'}

    ${({ readOnly }) => readOnly && `
      border: 1px solid var(--inputReadOnlyBorder);
      background-color: transparent;
      pointer-events: none;
    `}

    :hover {
      background-color: var(--inputHoverBackground);
    }
  }

  .MuiInputBase-root.Mui-focused {
    border: 1px solid var(--inputFocusedBorder);
  }

  .MuiInputBase-root.Mui-disabled {
    background-color: var(--inputDisabledBackground);

    input {
      cursor: auto;
    }
  }

  .MuiInputLabel-root.Mui-disabled {
    color: var(--inputDisabledText);
  }
`;
