import React from 'react';
import { FormControl, FormHelperText, Tooltip as MuiTooltip } from '@mui/material';
import cx from 'classnames';
import { useTheme } from '@mui/material/styles';

import Checkbox from 'components/Checkbox/CheckboxInput';
import { StyledMenuItem } from 'components/EditableSelect/styles';
import SelectComponent from './SelectComponent';
import { StyledInputLabel, TooltipAnchor, WrapperMenuItemWithTooltip, CloseIcon, LabelPrefix } from './styles';

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: -2,
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const menuRightProps = {
  ...menuProps,
  transformOrigin: {
    vertical: -2,
    horizontal: 'right',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};

const Select = ({
  value = '',
  options = [],
  required,
  disabled,
  label,
  error,
  readOnly,
  onEditOption,
  onAddOption,
  setError,
  setValue,
  setTouched,
  projectTypeId,
  formControlStyle,
  multiple,
  noLabel,
  selected,
  displayEmpty,
  theme,
  closeIcon,
  rightMenu,
  defaultValue,
  selectedPrefix,
  isLight,
  noClear,
  ...props
}) => {
  const muiTheme = useTheme();
  const hasOptions = options.length > 0;
  const noOptionsProps = {
    label: 'No options',
    disabled: true,
  };

  const handleChange = (e) => {
    if (e.target.value || e.target.value === 0) props.onChange(e);
  };

  const renderPrefixedLabel = (labelString) => (
    <>
      <LabelPrefix>{selectedPrefix}</LabelPrefix>
      {` ${labelString}`}
    </>
  );

  const renderSelectedValue = (selectedValue) => {
    if (!multiple) {
      const singleValue = options.find((option) => option.value === selectedValue);
      const selectedLabel = singleValue?.selectedLabel || singleValue?.label;
      const labelString = selectedPrefix
        ? renderPrefixedLabel(selectedLabel)
        : selectedLabel;
      return (!singleValue && displayEmpty) || labelString;
    }

    const multiValue = options
      .filter((option) => selectedValue.includes(option.value) && !option.ignore)
      .map((option) => {
        if (typeof option.label === 'string') {
          return option.label;
        }
        return option.label?.text;
      });
    return multiValue.join(', ');
  };

  const renderMenuItemWithTooltip = (option) => {
    return (
      <WrapperMenuItemWithTooltip key={option.value} value="">
        <StyledMenuItem
          className={cx((muiTheme.light || isLight) && 'light-theme')}
          disabled={option.disabled}
          multiple={multiple}
          style={{ width: '100%' }}
        >
          {multiple && (
            <Checkbox
              checked={value.includes(option.value)}
              disabled={option.disabled}
            />
          )}
          {option.label?.component || option.label}
        </StyledMenuItem>
        <MuiTooltip arrow title={option.tooltip}>
          <TooltipAnchor />
        </MuiTooltip>
      </WrapperMenuItemWithTooltip>
    );
  };

  const renderMenuItem = (option) => {
    if (option.tooltip && option.disabled) {
      return renderMenuItemWithTooltip(option);
    }
    return (
      <StyledMenuItem
        className={cx((muiTheme.light || isLight) && 'light-theme')}
        key={option.value}
        disabled={option.disabled}
        value={option.value}
        multiple={multiple}
      >
        {multiple && (
          <Checkbox
            checked={value.includes(option.value)}
            disabled={option.disabled}
          />
        )}
        {option.label?.component || option.label}
      </StyledMenuItem>
    );
  };

  return (
    <FormControl
      variant="filled"
      fullWidth
      required={required}
      error={!!error}
      disabled={disabled}
      style={formControlStyle}
    >
      {!noLabel && !displayEmpty && (
        <StyledInputLabel error={!!error}>
          {label}
        </StyledInputLabel>
      )}
      {!noLabel && displayEmpty && (
        <StyledInputLabel shrink={!!displayEmpty} error={!!error}>
          {label}
        </StyledInputLabel>
      )}

      <SelectComponent
        selected={selected}
        value={value}
        disableUnderline
        error={!!error}
        readOnly={readOnly}
        MenuProps={rightMenu ? menuRightProps : menuProps}
        renderValue={renderSelectedValue}
        defaultValue={defaultValue || ''}
        displayEmpty={!!displayEmpty}
        multiple={multiple}
        closeIcon={closeIcon}
        setValue={setValue}
        canBeCleared={!disabled && !readOnly}
        noClear={noClear}
        {...props}
        onChange={handleChange}
      >
        {displayEmpty && (
          <StyledMenuItem
            className={cx((muiTheme.light || isLight) && 'light-theme')}
            value=""
          >
            {displayEmpty}
          </StyledMenuItem>
        )}
        {hasOptions
          ? options.map((option) => renderMenuItem(option))
          : renderMenuItem(noOptionsProps)
        }
      </SelectComponent>

      {closeIcon && selected && <CloseIcon icon="crossSmall" noWrapper />}

      <FormHelperText>
        {error || ' '}
      </FormHelperText>
    </FormControl>
  );
};

export default Select;
