export function addRestrictionsToMenu(menu, isPackageGlobal) {
  if (!isPackageGlobal) {
    return {
      ...menu,
      'Global Ecosystem': {
        ...menu['Global Ecosystem'],
        disabled: true,
        tooltip: 'This feature is only available for Global package subscribers',
      },
    };
  }

  return menu;
}
