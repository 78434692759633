import React, { useState } from 'react';
import { useMatch, Link, matchPath } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';
import { List, ListItemText, Tooltip as MuiTooltip } from '@mui/material';

import AuthService, { ROLES_IDS } from 'services/AuthService';
import { Icon, PulsatingTooltip } from 'components';
import { PACKAGES_IDS } from 'modules/subscriptions/constants';

import { addRestrictionsToMenu } from './helpers';
import {
  Wrapper,
  Root,
  Logo,
  ToggleSidebarButton,
  StyledListItem,
  StyledItemIcon,
  DisabledItemWrapper,
  TooltipAnchor,
} from './styles';
import { COMPANY_TYPES } from 'types/company';

const hostStart = window.location.hostname.split('.')[0];
const allowedEnvironmentNames = ['staging', 'demo'];
const temporaryRestriction =
  !allowedEnvironmentNames.includes(hostStart) &&
  process.env.NODE_ENV !== 'development'
    ? {
      disabled: true,
      tooltip: 'Coming Soon!',
    }
    : null;

const MENU_ITEMS = {
  Dashboard: {
    icon: 'dashboard',
    path: '/dashboard',
    order: 0,
  },
  Subscriptions: {
    icon: 'subscriptions',
    path: '/subscriptions',
    order: 1,
  },
  Projects: {
    icon: 'projects',
    path: '/projects',
    order: 2,
  },
  'Global Ecosystem': {
    icon: 'globalEcosystem',
    path: '/global-ecosystem',
    hideForExternal: true,
    hiddenFor: [
      ROLES_IDS.DeveloperOwner,
      ROLES_IDS.RiskManager,
      ROLES_IDS.SiteContact,
    ],
    order: 3,
  },
  'My Company': {
    icon: 'myCompany',
    path: '/my-company',
    pulsatingTooltip: true,
    order: 4,
  },
  'Custom Reports': {
    icon: 'report',
    path: '/reports',
    disabled: true,
    tooltip: 'Not available in Beta',
    order: 5,
  },
  Library: {
    icon: 'library',
    path: '/library',
    order: 6,
  },
  Help: {
    icon: 'help',
    path: '/help',
    order: 7,
  },
  'Non-Subscribers': {
    icon: 'subscriptions',
    path: '/non-subscribers',
    order: 8,
  },
  Communications: {
    path: '/communications',
    icon: 'comment',
    order: 9,
  },
};

const defaultSidebarOpen = process.env.NODE_ENV !== 'development';

const Sidebar = () => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(defaultSidebarOpen);
  const { role, companyType, subscription } = AuthService.getUser();
  const isPackageGlobal = subscription === PACKAGES_IDS.Global;

  const menu = addRestrictionsToMenu(MENU_ITEMS, isPackageGlobal);

  const isRiskEngineer = role === ROLES_IDS.RiskEngineer;
  const isERE = isRiskEngineer && companyType === COMPANY_TYPES.NO_COMPANY;

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  const renderLink = (menuItem, menuLabel, routeMatch) => {
    if (menuItem.disabled) {
      return (
        <DisabledItemWrapper key={menuLabel}>
          <StyledListItem button open={isOpen} disabled>
            <StyledItemIcon title={menuLabel} selected={!!routeMatch}>
              <Icon icon={menuItem.icon} title={menuLabel} />
            </StyledItemIcon>
            {isOpen && <ListItemText primary={menuLabel} />}
          </StyledListItem>
          <MuiTooltip
            className={cx(theme.light && 'light-theme')}
            arrow
            title={menuItem.tooltip}
          >
            <TooltipAnchor />
          </MuiTooltip>
        </DisabledItemWrapper>
      );
    }
    return (
      <Link to={menuItem.path} key={menuLabel}>
        <StyledListItem button selected={!!routeMatch} open={isOpen}>
          <StyledItemIcon title={menuLabel} selected={!!routeMatch}>
            <Icon icon={menuItem.icon} title={menuLabel} />
            {menuItem.pulsatingTooltip && !routeMatch && isOpen && (
              <PulsatingTooltip place="Sidebar" />
            )}
          </StyledItemIcon>
          {isOpen && <ListItemText primary={menuLabel} />}
        </StyledListItem>
      </Link>
    );
  };

  return (
    <Root open={isOpen}>
      <Link to="/">
        <Logo open={isOpen}>
          <Icon icon="logo" />
        </Logo>
      </Link>

      <Wrapper open={isOpen}>
        <List>
          {Object.keys(menu)
            .filter((item) => AuthService.hasPermission(item, 'sidebar'))
            .sort((menuLabelA, menuLabelB) => {
              const menuItemA = menu[menuLabelA];
              const menuItemB = menu[menuLabelB];

              return menuItemA.order - menuItemB.order;
            })
            .map((menuLabel) => {
              const menuItem = menu[menuLabel];
              const routeMatch = useMatch(menuItem.path);
              if (menuItem.hideForExternal && isERE) {
                return null;
              }
              return renderLink(menuItem, menuLabel, routeMatch);
            })}
        </List>

        <ToggleSidebarButton open={isOpen} onClick={handleDrawerToggle}>
          <Icon icon="arrow" />
        </ToggleSidebarButton>
      </Wrapper>
    </Root>
  );
};

export default Sidebar;
