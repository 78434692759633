import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import LinearProgress from '@mui/material/LinearProgress';

import DocumentService from 'projects/api/DocumentService';
import { showNotifyError } from 'services/toaster';
import { formatSize } from 'utils/FormatUtils';

import { IconButton } from 'components';

import OpenAttachmentButton from '../OpenAttachmentButton';
import {
  Subtitle, ErrorMessage, ProgressWrapper, UploadingFileInfo,
  StyledIcon, StyledButton, UploadButton, FileRoot, FileInfo, ColoredIcon,
  FormatsString, FileSize, EmptyWrapper, EmptyMessage, PreviewWrapper, PreviewImage, PreviewEmptyIcon,
} from './styles';

const DEFAULT_MAX_FILE_SIZE = 26000000;

const UploadImage = ({
  name,
  setFieldsToChange,
  fileInfo, disabled,
  showFormats = true,
  onlyPicture,
  showEmptyMessage,
  forceUpdate,
  previewImage,
  reinitialize,
  noInfoInitialization,
  maxSize,
  label = 'Upload File',
}) => {
  const acceptImagesString = onlyPicture ? 'image/jpeg,image/png' : 'image/jpeg,image/png,image/gif,image/bmp,.pdf';
  const imageFormatsString = onlyPicture ? 'jpg, jpeg, png' : 'pdf, jpg, jpeg, png, gif, bmp';
  const maxSizeString = formatSize(maxSize);

  const [uploadFileName, setUploadFileName] = useState(fileInfo?.name);
  const [fileError, setFileError] = useState(null);
  const [newFileInfo, setNewFileInfo] = useState(fileInfo);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);

  const [totalSize, setTotalSize] = useState(null);

  function handleDeleteFile() {
    setFieldsToChange({
      name,
      value: '',
    });
    setUploadFileName(null);
    setIsUploading(false);
    setUploadProgress(null);
    setNewFileInfo(null);
  }

  const handleUploadProgress = ({ loaded, total }) => {
    const percentCompleted = Math.floor((loaded * 100) / total);
    setUploadProgress({
      percentCompleted,
      loaded,
      total,
    });
  };

  const uploadFile = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const isExtensionError = rejectedFiles[0].errors.some((error) => error.code === 'file-invalid-type');
      const isSizeError = rejectedFiles[0].errors.some((error) => error.code === 'file-too-large');
      if (isExtensionError) {
        setFileError(`The file format is not supported. Please use one of the following formats: ${imageFormatsString}.`);
      }
      if (isSizeError) {
        setFileError(`The file exceeds the maximum limit of ${maxSize ? maxSizeString : '25 MB'} and cannot be uploaded. Please try another file.`);
      }
      return;
    }

    setFileError(null);
    const file = acceptedFiles[0];

    setIsUploading(true);
    setUploadFileName(file.name);

    try {
      const { url, s3Key } = await DocumentService.getUploadFileUrl(null, file.name);
      const { config: { data } } = await DocumentService.uploadFileToAWS(file, url, handleUploadProgress);
      setTotalSize(data.size);
      const storedFile = await DocumentService.storeFileMetadata(null, s3Key, file.name);
      setNewFileInfo({ ...storedFile });
      setFieldsToChange({
        name,
        value: storedFile.id,
      });
    } catch (error) {
      let errMsg = error;

      if (error.status === 400 && error.fieldErrors) {
        errMsg = Object.values(error.fieldErrors).join('. ');
      }
      showNotifyError(errMsg);
    } finally {
      setIsUploading(false);
      setUploadProgress(null);
    }
  };

  const handleFileSelect = (acceptedFiles, rejectedFiles) => {
    uploadFile(acceptedFiles, rejectedFiles);
  };

  useEffect(() => {
    if (forceUpdate) {
      setNewFileInfo({ ...forceUpdate });
      setUploadFileName(forceUpdate.name);
    }
  }, [forceUpdate]);

  useEffect(() => {
    if (reinitialize && !fileInfo) {
      setNewFileInfo(null);
      setUploadFileName(null);
    } else if (fileInfo && !noInfoInitialization) {
      setUploadFileName(fileInfo.name);
      setFieldsToChange({
        name,
        value: fileInfo.id,
      });
    }
  }, [fileInfo]);

  useEffect(() => {
    setFileError(null);
  }, [disabled]);

  const { getInputProps, open } = useDropzone({
    onDrop: handleFileSelect,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: false,
    accept: acceptImagesString,
    maxSize: maxSize || DEFAULT_MAX_FILE_SIZE,
  });

  return (
    <>
      {!isUploading && (
        <UploadButton previewImage>
          <input {...getInputProps()} />
          {!uploadFileName && disabled && showEmptyMessage && (
            <EmptyWrapper>
              {previewImage && !uploadFileName && (
                <PreviewWrapper disabled>
                  <PreviewEmptyIcon icon="image" />
                </PreviewWrapper>
              )}
              <EmptyMessage previewImage={previewImage}>There is no file to show yet.</EmptyMessage>
            </EmptyWrapper>
          )}
          {!uploadFileName && !disabled && (
            <StyledButton
              small
              type="button"
              label="upload"
              color="primary"
              variant="text"
              text={label}
              onClick={open}
            >
              <StyledIcon icon="download" noWrapper />
            </StyledButton>
          )}
          {showFormats && !uploadFileName && !disabled && (
            <FormatsString>
              {`Supported formats: ${imageFormatsString}.`}
              {maxSize && ` Maximum size: ${maxSizeString}.`}
            </FormatsString>
          )}

          {uploadFileName && (
            <FileRoot>
              {previewImage && newFileInfo && newFileInfo.url ? (
                <OpenAttachmentButton fileInfo={newFileInfo} preview>
                  <PreviewWrapper>
                    <PreviewImage src={newFileInfo.url} alt="" />
                  </PreviewWrapper>
                </OpenAttachmentButton>
              ) : <ColoredIcon icon="unknown" noWrapper />}
              <FileInfo>
                {newFileInfo && newFileInfo.name ? (
                  <OpenAttachmentButton fileInfo={newFileInfo} attachedName />
                ) : (
                  <div>{uploadFileName}</div>
                )}
                {totalSize && <FileSize>{formatSize(totalSize)}</FileSize>}
              </FileInfo>
              {!disabled ? (
                <IconButton
                  icon="delete"
                  color="primary"
                  onClick={handleDeleteFile}
                  transparent
                />
              ) : (<span />)}
            </FileRoot>
          )}
        </UploadButton>
      )}

      {fileError && (
        <ErrorMessage>
          {fileError}
        </ErrorMessage>
      )}

      {isUploading && uploadProgress && (
        <UploadButton showFormats={showFormats}>
          <ColoredIcon icon="uploadFile" />
          <ProgressWrapper>
            <UploadingFileInfo>
              <Subtitle>
                {uploadFileName}
              </Subtitle>
              <div>
                {formatSize(uploadProgress.loaded)}
                {' of '}
                {formatSize(uploadProgress.total)}
              </div>
            </UploadingFileInfo>
            <LinearProgress variant="determinate" value={uploadProgress.percentCompleted} />
          </ProgressWrapper>
        </UploadButton>
      )}
    </>
  );
};

export default UploadImage;
